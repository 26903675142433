import React from 'react'
import { Link } from 'react-router-dom'
import { BsTelephoneInbound, BsEnvelope } from "react-icons/bs";
import './JumbotronNosotros.css'

const JumbotronNosotros = () => {

    return(
        <div className="us__jumbotron1--bg">
            <div className="us__jumbotron1">
                <div className="us__jumbotron1--contText">
                    <div className="us__jumbotron1--title">J&T Solutions Inc.</div>
                    <div className="us__jumbotron1--line"></div>
                    <div className="us__jumbotron1--text">Brindamos herramientas que ayudan a disminuir costos logísticos, mejorando los procesos de seguridad y control de vehículos y flotillas</div>
                    <div className="us__jumbotron1--buttons">
                        <Link to='/Soluciones'><button className="us__jumbotron1--button1">Soluciones</button></Link>
                        <Link to='/Contacto'><button className="us__jumbotron1--button2">Contacto</button></Link>
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default JumbotronNosotros;