import React from 'react'
import Card from './Card'
import './CardComplementos.css'
import LogoIcon from '../../Media/logo_ícono_transparente.png'
import Complementos1 from '../../Media/complementos_1.jpg'
import Complementos2 from '../../Media/complementos_2.jpg'
import Complementos3 from '../../Media/complementos_3.jpg'
import Complementos4 from '../../Media/complementos_4.jpg'
import Complementos5 from '../../Media/complementos_5.jpg'




const CardComplementos = () => {
    return (
        <div className="start__complements--complements">
            <div><img src={LogoIcon} alt="Logo Icono" className="complements__process--logoicon" /></div>
            <div className="complements__process--text">Mejora las soluciones de rastreo con un complemento de telemática automotriz, logística y manejo de flotas.
             </div>
             <div className="complements__process--cards">
                <Card titulo='Telemetría' contenido='Complemento de sistema de localización, que permite obtener datos de la computadora de su vehículo en tiempo real.' imagen={Complementos1}/>

                <Card titulo='Control de Combustible FLS' contenido='Complemento de sistema de localización, capaz de identificar las cargas de combustible y extracciones no autorizadas.' imagen={Complementos2}/>

                <Card titulo='Control de Temperatura' contenido='Complemento capaz de conocer el comportamiento de la temperatura en tiempo real durante el viaje.' imagen={Complementos3}/>

                <Card titulo='Comunicación Bidireccional' contenido='Complemento de sistema de localización, el cual permite tener comunicación directa con el conductor de su unidad.' imagen={Complementos4}/>

                <Card titulo='Multimedia' contenido='Sistema inteligente de localización capaz de capturar fotografías en tiempo real.' imagen={Complementos5}/>
             </div>
        </div>
            
    )
}

export default CardComplementos