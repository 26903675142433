import React from 'react'
import { Link } from 'react-router-dom'
import './JumbotronSoluciones.css'

const JumbotronSoluciones = () => {

    return(
        <div className="solutions__jumbotron1--bg">
            <div className="solutions__jumbotron1">
                <div className="solutions__jumbotron1--contText">
                    <div className="solutions__jumbotron1--title">Rastreo de vehículos y activos</div>
                    <div className="solutions__jumbotron1--line"></div>
                    <div className="solutions__jumbotron1--text">Soluciones inalámbricas de última tecnología, especializados en el monitoreo de activos vía GPS/LTE/3G/GSM.</div>
                    <div className="solutions__jumbotron1--buttons">
                    <Link to='/Contacto'><button className="solutions__jumbotron1--button1">Contacto</button></Link>
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default JumbotronSoluciones;