import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import Slider from '../Slider/Slider'
import Jumbotron from '../Jumbotron/Jumbotron'
import Jumbotron2 from '../Jumbotron/Jumbotron 2'
import './Inicio.css'
import { BsCardList, BsPuzzle, BsReceiptCutoff } from "react-icons/bs";

const Inicio = () => {
    return (
        <div>
            <div className="start__inicio--slide">
                <Slider />
            </div>
            <div className="start__inicio--aboutus">
                <Jumbotron />
            </div>
            <div className="start__inicio--start">
                <div className="inicio__start--title">¿Cómo comenzar?</div>
                <div className="inicio__start--text">Si buscas soluciones de rastreo pero no sabes por dónde comenzar,<br/>
                 te recomendamos seguir los siguientes pasos:
                 </div>
                 <div className="inicio__start--cards">
                     <div className='start__cards--1'>
                        <div className="start__cards--icon"><BsCardList /></div>
                        <div className="start__cards--title">1. Selecciona un paquete</div>
                        <div className="start__cards--text">En la sección Soluciones encontrarás nuestrosaquetes de rastreo y video vigilancia.</div>
                        <Link to='/Soluciones'><button className='start__cards--button'>Ver Soluciones</button></Link>
                     </div>
                     <div className='start__cards--2'>
                        <div className="start__cards--icon"><BsPuzzle /></div>
                        <div className="start__cards--title">2. Añade un Complemento</div>
                        <div className="start__cards--text">Los complementos o Add-ons ayudan a mejora las capacidades de los paquetes.</div>
                        <Link to='/Complementos'><button className='start__cards--button'>Ver Complementos</button></Link>
                     </div>
                     <div className='start__cards--3'>
                        <div className="start__cards--icon"><BsReceiptCutoff /></div>
                        <div className="start__cards--title">3. Solicita una Cotización</div>
                        <div className="start__cards--text">Elaboramos una cotización con base en tus necesidades y el número de unidades a rastrear.</div>
                        <Link to='/Contacto'><button className='start__cards--button'>Solicitar Cotización</button></Link>
                     </div>
                 </div>

            </div>
            <div className="start__inicio--contact">
                <Jumbotron2 />
            </div>


        </div>
    )
}

export default Inicio
