import React from 'react'
import Card from './Card'
import './Soluciones.css'
import JumbotronSoluciones from './JumbotronSoluciones'
import LogoIcon from '../../Media/logo_ícono_transparente.png'
import Soluciones1 from '../../Media/soluciones__1.jpg'
import Soluciones2 from '../../Media/soluciones__2.jpg'
import Soluciones3 from '../../Media/soluciones__3.png'
import Soluciones4 from '../../Media/soluciones__4.jpg'
import Soluciones5 from '../../Media/soluciones__5.jpg'
import Soluciones6 from '../../Media/soluciones__6.jpg'



const Soluciones = () => {
    return (
        <div><div><JumbotronSoluciones/></div>
        <div className="start__soluciones--process">
            <div><img src={LogoIcon} alt="Logo Icono" className="soluciones__process--logoicon" /></div>
            <div className="soluciones__process--text">J&T Solutions Inc. brinda soluciones que permiten la optimización, <br/>
            mejora en los procesos de seguridad, control del uso de vehículos, flotillas y  conductores.
             </div>
             <div className="soluciones__process--cards">
                <Card titulo='Rastreo Satelital' contenido='Sistema de rastreo satelital en tiempo real con amplia 
                    variedad de aplicaciones que permiten evaluar la rentabilidad de su negocio.' imagen={Soluciones1}/>

                <Card titulo='Rastro de Maquinaria' contenido='Sistema localización en tiempo real de manera rápida y sencilla, 
                    capaz de optimizar sus ingresos controlando las horas reales de trabajo.' imagen={Soluciones2}/>

                <Card titulo='Video Vigilancia' contenido='Localización satelital con tecnología avanzada, compatible con cámaras de video de alta definición en tiempo real.' imagen={Soluciones3}/>

                <Card titulo='Rastreo de Motocicletas' contenido='Sistema de localización en tiempo real con amplia cobertura, especializado para motocicletas.' imagen={Soluciones4}/>

                <Card titulo='Rastreo de Activos' contenido='Sistema de localización especializado para activos tales como contenedores, remolques tolvas y de uso personal.' imagen={Soluciones5}/>

                <Card titulo='Remolque' contenido='Optimiza la operación y el protocolo de seguridad de tu flotilla mediante un sistema robusto de interpolación Unidad/Remolque.' imagen={Soluciones6}/>
             </div>
        </div></div>
            
    )
}

export default Soluciones