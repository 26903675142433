import React from 'react'
import JumbotronComplementos from './JumbotronComplementos'
import CardComplementos from './CardComplementos'
import './Complementos.css'


const Complementos = () => {
    return (
        <div>
            <div><JumbotronComplementos/></div>
            <div><CardComplementos/></div>
        </div>
    )
}

export default Complementos