import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import { BsTelephoneInbound, BsEnvelope } from "react-icons/bs";

import './Jumbotron.css'

const Jumbotron = () => {

    return(
        <div className="start__jumbotron2--bg">
            <div className="start__jumbotron2">
                <div className="start__jumbotron2--contText">
                <div className="start__jumbotron2--text">¿Requieres asesoría de un experto?</div>
                    <div className="start__jumbotron2--title">Contáctanos ahora</div>
                    <div className="start__jumbotron2--icon"><BsTelephoneInbound /></div>
                    <div className="start__jumbotron2--title">Teléfono Ventas</div>
                    <div className="start__jumbotron2--text">(+52) 442 561 90 49</div>
                    
                    <div>
                        <Link to='/contacto'><button className="start__jumbotron2--button">Enviar Mensaje <BsEnvelope  className="start__jumbotron2--icon2"/></button></Link>
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default Jumbotron