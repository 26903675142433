import React, { useState } from 'react'
// import { ReactCSSTransitionGroup } from 'react-transition-group';
import './Slider.css'
import Slid1 from '../../Media/slider_inicio_1.jpg'
import Slid2 from '../../Media/slider_inicio_2.jpg'
import Slid3 from '../../Media/slider_inicio_3.jpg'
import Slid4 from '../../Media/slider_inicio_4.jpg'
import Slid5 from '../../Media/slider_inicio_5.jpg'

import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";

const Slider = () => {
    const [slideCount, setslideCount] = useState(1)

    const backSlide = () => {
        if (slideCount === 1) {
            setslideCount(5)
        } else {
            setslideCount(slideCount - 1)
        }
    }

    const nextSlide = () => {
        if (slideCount === 5) {
            setslideCount(1)
        } else {
            setslideCount(slideCount + 1)
        }

    }

    return (


        <div className="start__slider">

            <div className="start__slider--slideLeft" >
                <BsArrowLeftCircle onClick={() => { backSlide() }} className="start__slider--buttonChange" />
            </div>
            <div className="start__slide--contentImg">
                <div>
                    <img src={Slid1} alt="jtsolutions_products" className={slideCount === 1 ? ("start__slider--image start__slider--animation") : ("start__slider--imageNone")} />
                </div>
                <div>
                    <img src={Slid2} alt="jtsolutions_products" className={slideCount === 2 ? ("start__slider--image start__slider--animation") : ("start__slider--imageNone")} />
                </div>

                <div>
                    <img src={Slid3} alt="jtsolutions_products" className={slideCount === 3 ? ("start__slider--image start__slider--animation") : ("start__slider--imageNone")} />
                </div>

                <div>
                    <img src={Slid4} alt="jtsolutions_products" className={slideCount === 4 ? ("start__slider--image start__slider--animation") : ("start__slider--imageNone")} />
                </div>

                <div>
                    <img src={Slid5} alt="jtsolutions_products" className={slideCount === 5 ? ("start__slider--image start__slider--animation") : ("start__slider--imageNone")} />
                </div>
            </div>
            <div className="start__slider--slideRight" >
                <BsArrowRightCircle onClick={() => { nextSlide() }} className="start__slider--buttonChange" />
            </div>
            <div className="start_slider--menuChooser">
                <button className={slideCount === 1 ? ("start__slider--chooser start__slider--chooserSelected") : ("start__slider--chooser")} onClick={() => { setslideCount(1) }}></button>
                <button className={slideCount === 2 ? ("start__slider--chooser start__slider--chooserSelected") : ("start__slider--chooser")} onClick={() => { setslideCount(2) }}></button>
                <button className={slideCount === 3 ? ("start__slider--chooser start__slider--chooserSelected") : ("start__slider--chooser")} onClick={() => { setslideCount(3) }}></button>
                <button className={slideCount === 4 ? ("start__slider--chooser start__slider--chooserSelected") : ("start__slider--chooser")} onClick={() => { setslideCount(4) }}></button>
                <button className={slideCount === 5 ? ("start__slider--chooser start__slider--chooserSelected") : ("start__slider--chooser")} onClick={() => { setslideCount(5) }}></button>
            </div>
        </div>
    )
}

export default Slider