import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import "./Topbar.css";
import MobileMenu from './MobileMenu'
import { BsWhatsapp, BsEnvelope, BsList } from "react-icons/bs";
import Logo from "../../Media/logo.jpeg";

const Topbar = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      {menuOpen && <MobileMenu setMenuOpen={setMenuOpen} />}

      <div className="start__topbar--contact">
        <div className="start__topbar--text">
          <BsWhatsapp /> 442 561 90 49
        </div>
        <div>
          <Link to="/Contacto">
            <div className="start__topbar--info">
              <BsEnvelope className="start__topbar--infologo"/>Informes
            </div>
          </Link>
        </div>
        
        
      </div>
      <div className="start__topbar--menu">
        <div className="start__topbar--section1">
          <img
            src={Logo}
            alt="logo de jtsolutions"
            className="start__topbar--logo"
          />
        </div>
        <div className="start__topbar--section2">
         
          <Link to="/inicio">
            <button className="menu_option">Inicio</button>
          </Link>
          <Link to="/Nosotros">
            <button className="menu_option">Nosotros</button>
          </Link>
          <Link to="/Soluciones">
            <button className="menu_option">Soluciones</button>
          </Link>
          <Link to="/Complementos">
            <button className="menu_option">Complementos</button>
          </Link>
          <Link to="/Servicios">
            <button className="menu_option">Servicios</button>
          </Link>
          <Link to="/Criptoactivos">
            <button className="menu_option">Criptoactivos</button>
          </Link>
          <a href="https://track.jtsolutions.com.mx/?lang=es" className="menu_option"> Tracking </a>
          <Link to="/Contacto">
            <button className="contact">Contacto</button>
          </Link>
          <button className="start__topbar--list" onClick={() => {setMenuOpen(true)}}>
            <BsList />
          </button>
        </div>
      </div>
    </div>
  );
};


export default Topbar;
