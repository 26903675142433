import React from 'react'
import Card from './Card'
import './CardServicios.css'
import LogoIcon from '../../Media/logo_ícono_transparente.png'
import Servicios1 from '../../Media/servicios_1.jpg'
import Servicios2 from '../../Media/servicios_2.jpg'
import Servicios3 from '../../Media/servicios_3.jpg'
import Servicios4 from '../../Media/servicios_4.jpg'
import Servicios5 from '../../Media/servicios_5.jpg'





const CardServicios = () => {
    return (
        <div className="start__services--services">
            <div><img src={LogoIcon} alt="Logo Icono" className="services__process--logoicon" /></div>
            <div className="services__process--text">Mejora las soluciones de rastreo con un complemento de telemática automotriz, logística y manejo de flotas.
             </div>
             <div className="services__process--cards">

                <Card titulo='Soporte técnico y asesoría remota' contenido='Soporte técnico las 24 horas los 365 días del año. Permite obtener el máximo beneficio del servicio contratado, así como mejorar la
                satisfacción del cliente.' imagen={Servicios1}/>

                <Card titulo='Capacitación y Levantamiento' contenido='Comunicar de forma efectiva las funciones de plataforma así como su configuración personalizada para la ejecución de los requerimientos del cliente.' imagen={Servicios2}/>

                <Card titulo='Asesoría comercial' contenido='Asesoría comercial sobre las soluciones adecuadas a su negocio para disminuir las fugas económicas e incrementar la rentabilidad de su negocio.' imagen={Servicios3}/>

                <Card titulo='Atención a Siniestros' contenido='Atención a en caso de robo o siniestro enviando un enlace de ubicación en tiempo real y reportes detallados para ayudar a la recuperación de la unidad.' imagen={Servicios4}/>

                <Card titulo='Certificados de calidad' contenido='Garantiza la efectividad del paquete contratado a través de informes de evidencias que permiten detectar y resolver posibles fallas en el sistema.' imagen={Servicios5}/>
             </div>
        </div>
            
    )
}

export default CardServicios