import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Complementos from './Components/Complementos/Complementos'
import Contacto from './Components/Contacto/Contacto'
import Inicio from './Components/Inicio/Inicio'
import Nosotros from './Components/Nosotros/Nosotros'
import Servicios from './Components/Servicios/Servicios'
import ImageSlider from './Components/Slider/ImageSlider'
import Criptoactivos from './Components/Criptoactivos/Criptoactivos'
import Soluciones from './Components/Soluciones/Soluciones'
import Topbar from './Components/Topbar/Topbar'
import Footer from './Components/Footer/Footer'

const RoutesPage = () => {
    return(
        <div>
            <Topbar/>
            {/* <ImageSlider/> */}
            <Routes>
                <Route exact path="/inicio" element ={<Inicio/>} />
                <Route exact path="/nosotros" element ={<Nosotros/>} />
                <Route exact path="/soluciones" element ={<Soluciones/>}/>
                <Route exact path="/complementos" element ={<Complementos/>} />
                <Route exact path="/servicios" element ={<Servicios/>} />
                <Route exact path="/criptoactivos" element ={<Criptoactivos/>} />
                <Route exact path="/contacto" element ={<Contacto/>} />
                <Route path="/" element ={<Navigate replace to='/inicio' />} />
                <Route path="*" element ={<Navigate replace to='/inicio' />} />
                    
            </Routes> 
            <Footer/>
        </div>
    );
};

export default RoutesPage
