import React from 'react'
import './JumbotronNosotros.css'
import { BsTrophy, BsEye } from "react-icons/bs";

const GridCardsNosotros = () => {
    return (
        <div>
            <div className="nosotros__grid--bg">
                <div className="nosotros__grid--cards">
                    <div className='grid__cards--1'>
                        <div className="grid__cards--icon"><BsTrophy /></div>
                        <div className="grid__cards--title">MISIÓN</div>
                        <div className="grid__cards--line"></div>
                        <div className="grid__cards--text">Proveer tranquilidad y confiabilidad a personas o empresas mexicanas mediante herramientas de la más alta tecnología de rastreo y telemetría.</div>
                    </div>
                    <div className='grid__cards--2'>
                        <div className="grid__cards--icon"><BsEye /></div>
                        <div className="grid__cards--title">VISIÓN</div>
                        <div className="grid__cards--line"></div>
                        <div className="grid__cards--text">Ser la empresa de localización y telemetría con la mayor cobertura de la región mediante la sistematización, consolidación y eficiencia de sus procesos.</div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default GridCardsNosotros;