import React from 'react'
import JumbotronServicios from './JumbotronServicios'
import CardServicios from './CardServicios'
import './Servicios.css'


const Servicios = () => {
    return (
        <div>
            <div><JumbotronServicios/></div>
            <div><CardServicios/></div>
        </div>
    )
}

export default Servicios