import React from 'react'
import './Contacto.css'

const Contacto = () => {
    return (
        <div>
            <form className='start__contact--form'>
                <h2 className='contact__form--title'>Deja tus datos y nos comunicaremos contigo.</h2>
                <div className="contact__form--data">
                    <div className="form__data">Nombre Completo:</div>
                    <input placeholder="Nombre Apellido" className="form__name--place"/>
                    <div className="form__data">Correo Electrónico:</div>
                    <input placeholder="ejemplo@mail.com" className="form__name--place"/>
                    <div className="form__data">Teléfono:</div>
                    <input placeholder="- - - - - - - - - -" className="form__name--place"/>
                    <div className="form__data">Mensaje:</div>
                    <input placeholder="Escribe tu mensaje" className="form__name--place"/>
                </div>
                <button className='contact__form--button'>Enviar Información</button>
            </form>
        </div>
    )
}

export default Contacto