
import React from 'react';
import RoutesPage from './Routes'
import ScrollToTop from "../src/Components/Topbar/ScrollToTop";

import './App.css';

function App() {
  return (
    <div className>
      <ScrollToTop />
      <RoutesPage />

    </div>
  );
}

export default App;
