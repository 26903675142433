import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import jumbotron_inicio_1 from '../../Media/jumbotron_inicio_1.jpg'

import './Jumbotron.css'

const Jumbotron = () => {

    return(
        <div className="start__jumbotron">
            <div className="start__jumbotron--contImg">
                <img src={jumbotron_inicio_1} alt="soporte de jtsolutions" className="start__jumbotron--img"/>
            </div>
            <div className="start__jumbotron--contText">
                <div className="start__jumbotron--title ">J&T Solutions Inc.</div>
                <div className="start__jumbotron--text ">Ofrecemos soluciones inalámbricas de última tecnología, especializadas en el monitoreo de activos vía GPS/LTE/3G/GSM.</div>
                <div>
                    <Link to='/Nosotros'><button className="start__jumbotron--button ">Conócenos</button></Link>               </div>
            </div>
        </div>
    )
}

export default Jumbotron