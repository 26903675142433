import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'
import './Topbar.css'
import Logo from "../../Media/logo_borde.png";
import { BsChevronUp } from "react-icons/bs";

const MobileMenu = (props) => {



    return (
        <div className="mobile__menu">
            <div className="mobile__menu--logo">
                <Link to="/home">
                    <img src={Logo}
                        alt="" className="mobile__menu--image" 
                        onClick={() => props.setMenuOpen(false)}/>
                </Link>
            </div>
            <div className="mobile__menu--closeContent">
                <button className="mobile__menu--close" onClick={() => props.setMenuOpen(false)}>
                    <i className="fas fa-times"><BsChevronUp /></i>
                </button>
            </div>
            <div className="mobile__menu--buttonContent">
                
                

                
                <br /><br />


                <div className="mobile__menulist">
                     <Link to="/inicio">
                        <div className="mobile__menulist--button" onClick={() => props.setMenuOpen(false)}> 
                            Inicio  
                        </div>
                    </Link>
                    <Link to="/Nosotros">
                        <div className="mobile__menulist--button" onClick={() => props.setMenuOpen(false)}> 
                            Nosotros 
                        </div>
                    </Link>
                    <Link to="/Soluciones">
                        <div className="mobile__menulist--button" onClick={() => props.setMenuOpen(false)}>
                            Soluciones 
                        </div>
                    </Link>
                    <Link to="/Complementos">
                        <div className="mobile__menulist--button" onClick={() => props.setMenuOpen(false)}>
                            Complementos
                        </div>
                    </Link>
                    <Link to="/Servicios">
                        <div className="mobile__menulist--button" onClick={() => props.setMenuOpen(false)}>
                            Servicios 
                        </div>
                    </Link>
                    <Link to="/Contacto">
                        <div className="mobile__menulist--button" onClick={() => props.setMenuOpen(false)}>
                            Contacto 
                        </div>
                    </Link>
                    <a href="https://track.jtsolutions.com.mx/?lang=es">
                        <div className="mobile__menulist--button" onClick={() => props.setMenuOpen(false)}> Tracking 
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu