import React from 'react'
import { Link } from 'react-router-dom'
import { BsTelephoneInbound, BsEnvelope } from "react-icons/bs";
import './JumbotronComplementos.css'

const JumbotronComplementos = () => {

    return(
        <div className="complements__jumbotron1--bg">
            <div className="complements__jumbotron1">
                <div className="complements__jumbotron1--contText">
                    <div className="complements__jumbotron1--title">Add-Ons</div>
                    <div className="complements__jumbotron1--line"></div>
                    <div className="complements__jumbotron1--text">Los Add-Ons son complementos que se pueden agregar a nuestras soluciones de rastreo para mejorar y expandir las capacidades, rendimiento y funcionalidades.</div>
                    <div className="complements__jumbotron1--buttons">
                    <Link to='/Soluciones'><button className="complements__jumbotron1--button1">Soluciones</button></Link>
                    </div>
                </div>                
            </div>
        </div>
        
    )
}

export default JumbotronComplementos;