import React from 'react'
import './Footer.css'
import LogoBorde from '../../Media/logo_borde.png'

const Footer = () => {
    return (
        <div className='start__footer--box'>
            <div><img src={LogoBorde} alt="Logo" className="start__footer--logo"/></div>
            <div className="start__footer--text">Todos los Derechos Reservados 2021 © J&T Solutions Inc.</div>
            <div className="start__footer--development">Sitio Desarrollado por PandaBear Soft®</div>
        </div>
    )
}

export default Footer