import React from 'react'
import { Link } from 'react-router-dom'
import jumbotron_inicio_1 from '../../Media/logo_borde.png'
import './JumbotronNosotros.css'

const JumbotronNosotros2 = () => {

    return(
        <div className="us__jumbotron2--bg">
            <div className="us__jumbotron2">
                <div className="us__jumbotron2--contImg">
                    <img src={jumbotron_inicio_1} alt="soporte de jtsolutions" className="us__jumbotron2--img"/>
                </div>
                <div className="us__jumbotron2--contText">
                    <div className="us__jumbotron2--title">Más de 15 años de experiencia</div>
                    <div className="us__jumbotron2--text">J&T Solutions Inc. fue fundada en 2010 por ingenieros en electrónica y comunicaciones con experiencia desde 2005 en el ramo del rastreo, telecomunicaciones y telemetría.</div>
                    <div>
                    <Link to='/Servicios'><button className="us__jumbotron2--button ">Servicios</button></Link>
                    </div>
                </div>
            </div>
        </div>
        

    )
}

export default JumbotronNosotros2;