import React from "react";
import "./CardServicios";

const Card = (props) => {
    console.log(props);
  return (
      <div className="services__cards--1">
        <div className="services__cards--image"><img src={props.imagen} alt="" className="services__cards--image1"/></div>
        <div className="services__cards--2">
            <div className="services__cards--title">{props.titulo}</div>
            <div className="services__cards--text">{props.contenido}</div>
        </div>
        
      </div>
  );
};

export default Card;
