import React from 'react'
import JumbotronNosotros from './JumbotronNosotros'
import JumbotronNosotros2 from './JumbotronNosotros2'
import GridCardsNosotros from './GridCardsNosotros'
import './JumbotronNosotros.css'

const Soluciones = () => {
    return (
        <div>
           <div>
                <JumbotronNosotros/>
                <JumbotronNosotros2/>
                <GridCardsNosotros/>
            </div>
        </div>
    )
}

export default Soluciones;