import React from 'react'
import { Link } from 'react-router-dom'
import { BsTelephoneInbound, BsEnvelope } from "react-icons/bs";
import './JumbotronServicios.css'

const JumbotronServicios = () => {

    return(
        <div className="services__jumbotron1--bg">
            <div className="services__jumbotron1">
                <div className="services__jumbotron1--contText">
                    <div className="services__jumbotron1--title">Atención al Cliente</div>
                    <div className="services__jumbotron1--line"></div>
                    <div className="services__jumbotron1--text">Excelencia en el servicio y atención al cliente, mediante un enfoque en la calidad de nuestros procesos y asegurando la rentabilidad de su negocio.</div>
                    <div className="services__jumbotron1--buttons">
                    <Link to='/Soluciones'><button className="services__jumbotron1--button1">Soluciones</button></Link>
                    </div>
                </div>                
            </div>
        </div>
        
    )
}

export default JumbotronServicios;