import React from 'react'
import './Criptoactivos.css'


const Criptoactivos = () => {
    return (
        <div className='start__cripto--card'>
            <div className='start__cripto--card1'>
                <div className='cripto__card--title'>Pago con Criptos</div>
                <div className="cripto__card--text">Realiza el pago de productos y servicios en BTC, ADA, ETH, BNB, DOT, y otras. </div>
            </div>
            <div className='start__cripto--card2'>
                <div className='cripto__card--title'>Inversión</div>
                <div className="cripto__card--text">Gestiona tu inversión en criptoactivos.</div>
            </div>
            <div className='start__cripto--card3'>
                <div className='cripto__card--title'>Trading</div>
                <div className="cripto__card--text">Realiza operaciones en el mercado mediante iBot</div>
            </div>
            <div className='start__cripto--card4'>
                <div className='cripto__card--title'>Asesoría</div>
                <div className="cripto__card--text">Diseño de estrategia de inversión según tus necesidades</div>
            </div>
        </div>
    )
}

export default Criptoactivos